/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
#      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
#        childImageSharp {
#            gatsbyImageData(layout: FIXED, width: 50, height: 50)
#        }
#      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5)
      }}
    >

      <StaticImage height={50} src="../../static/images/profile-pic.png" alt={author.name}
       style={{
         marginRight: rhythm(1 / 2),
         marginBottom: 0,
         minWidth: 50,
         borderRadius: `100%`
       }}
       imgStyle={{
         borderRadius: `50%`
       }}
      />

      <p>
        Written by <strong>{author.name}</strong> {author.summary}
        {` `}
        <a href={`https://twitter.com/${social.twitter}`}>
          You should follow him on Twitter
        </a>
      </p>
    </div>
  )
}

export default Bio
